(function () {
    'use strict';

    angular.module('adminApp')
        .factory('AddAdminVendorModal', AddAdminVendorModal)
        .controller('AddAdminVendorController', AddAdminVendorController);

    AddAdminVendorModal.$inject = ['$uibModal'];

    function AddAdminVendorModal($uibModal) {
        function open(vendorModel) {
            var modal = $uibModal.open({
                size: "md",
                resolve: {
                    vendorModel: function () {
                        return vendorModel;
                    }
                },
                templateUrl: "/static/templates/admin/modals/AddAdminVendorModal.html",
                controller: "AddAdminVendorController"
            });
            return modal.result;
        }

        return {
            open: open
        };
    }

    AddAdminVendorController.$inject = ['$scope', '$uibModalInstance', 'Notification', 'aerosAdminApi', 'vendorModel'];

    function AddAdminVendorController($scope, $uibModalInstance, Notification, aerosAdminApi, vendorModel) {
        angular.extend($scope, {
            addVendor: addVendor,
            updateVendor: updateVendor
        });

        (function init() {
            $scope.vendorModel = vendorModel;
        })();

        function addVendor() {

            var vendor = angular.fromJson(angular.toJson($scope.vendorModel));
            vendor.id = vendor.idToAdd;
            delete vendor.idToAdd;

            aerosAdminApi.vendorApi.create({
                vendor: vendor
            }).then(function(results) {
                $uibModalInstance.close({vendorId:vendor.id, uploadLogo: $scope.uploadLogo});
                Notification.success("Vendor successfully added");
            }, function(error) {
                Notification.error("Vendor failed to add. " + error.data.message);
            });
        }

        function updateVendor() {
            aerosAdminApi.vendorApi.update($scope.vendorModel).then(function(results) {
                $uibModalInstance.close({vendorId:$scope.vendorModel.id, uploadLogo: $scope.uploadLogo});
                Notification.success("Vendor successfully updated");
            }, function(error) {
                Notification.error("Vendor failed to update. " + error.data.message);
            });
        }

    }

})();